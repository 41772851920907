export enum StorageKey {
  jwt = 'jwt',
  currentSiteId = 'currentSiteId',
  currentAccountId = 'currentAccountId'
}

type StoreSuccess = boolean;

export default class LocalStorageUtil {
  public static store(key: StorageKey, value: string | number): StoreSuccess {
    try {
      if (window.localStorage) {
        window.localStorage.setItem(key, value + '');
        return true;
      }
    } catch (e) {
    }
    return false
  }

  public static get(key: StorageKey): string | null {
    try {
      if (localStorage) {
        return localStorage.getItem(key);
      }
    } catch (e) {
    }
    return null;
  }

  public static delete(key: StorageKey): StoreSuccess {
    try {
      if (localStorage) {
        localStorage.removeItem(key);
        return true;
      }
    } catch (e) {
    }

    return false;
  }
}