import * as Sentry from '@sentry/react';
import FetchUtil from "./FetchUtil";
import Environment from "components/Environment";

export default class AppVersionUtil {
  static async verify(): Promise<void> {
    const clientVersion = process.env.REACT_APP_SOURCE_VERSION;

    if (!clientVersion) {
      const message = "Client's version does not seem to be a proper value: " + clientVersion;
      console.warn(message);
      if (Environment.isProduction()) Sentry.captureException(message);

      return;
    }

    try {
      const appVersion: string = await FetchUtil.fetchText(`/api/app_version`);

      if (!appVersion) {
        const message = "Failed to retrieve a proper App Version: " + appVersion;
        console.warn(message);
        if (Environment.isProduction()) Sentry.captureException(message);

        return;
      }

      if (appVersion !== clientVersion) {
        console.log("App version updated. Refreshing the screen.");
        window.location.reload(true);
      }

    } catch (e) {
      console.error("Could not get the app version ", e);
    }
  }
}